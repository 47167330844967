<!--
 * @Description: 驱动新增 - 添加端口
 * @Author: kecraft
 * @Date: 2024-02-27 15:39:21
 * @LastEditors: kecraft
 * @LastEditTime: 2024-02-29 10:09:06
 * @FilePath: /impact-iotos-console/src/views/drive/list/pointAdd.vue
-->
<!--
 * @Description: 驱动管理 - 任务新增
 * @Author: kecraft
 * @Date: 2024-02-27 10:11:18
 * @LastEditors: kecraft
 * @LastEditTime: 2024-02-27 10:29:09
 * @FilePath: /impact-iotos-console/src/views/drive/task/taskAdd.vue
-->
<template>
  <div class="task-add">
    <myDialog @close="handleClose" @ok="handleOk" title="添加端口" width="700px">
      <el-form :rules="rules" ref="pointAddRef" :model="form" label-width="110px">
        <el-form-item label="端口" prop="port">
          <el-input-number v-model="form.port" style="width: 512px;" placeholder="请输入端口" />
        </el-form-item>
        <el-form-item label="协议" prop="protocol">
          <el-select v-model="form.protocol" placeholder="请选择" style="width: 512px;">
            <el-option v-for="item in protocolList" :key="item.value" :label="item.name" :value="item.name" />
          </el-select>
        </el-form-item>
        <el-form-item label="说明" prop="desc">
          <el-input v-model="form.desc" style="width: 512px;" placeholder="请输入说明" />
        </el-form-item>
      </el-form>
    </myDialog>
  </div>
</template>

<script setup>
import myDialog from "@/components/dialog/myDialog.vue";
import { ref, reactive, defineEmits } from "vue";
const emit = defineEmits(["close"]);
const protocolList = ref([
  {
    name: "TCP",
    value: "TCP",
  },
  {
    name: "UDP",
    value: "UDP",
  },
  {
    name: "HTTP",
    value: "HTTP",
  },
  {
    name: "MQTT",
    value: "MQTT",
  },
  {
    name: "WS",
    value: "WS",
  }
]);
const form = reactive({
  port: "", // 端口
  desc: "", // 说明
  protocol: "", // 协议
})
const rules = {
  port: [
    { required: true, message: "端口不能为空", trigger: "change" },
  ],
  protocol: [
    { required: true, message: "协议不能为空", trigger: "change" },
  ],
}

const pointAddRef = ref(null);
const handleClose = () => {
  emit("close")
}
const handleOk = () => {
  pointAddRef.value.validate((valid) => {
    if (valid) {
      emit("close", form)
    } else {
      return false;
    }
  });
}
</script>