<!--
 * @Description: 驱动列表 - 创建驱动
 * @Author: kecraft
 * @Date: 2024-02-27 10:30:08
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-29 09:11:17
 * @FilePath: /impact-iotos-console/src/views/drive/list/driveAdd.vue
-->
<template>
  <div class="drive-add">
    <div class="breadcrumb-conatiner">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/drive/list' }">驱动列表</el-breadcrumb-item>
        <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="drive-container">
      <!-- 驱动信息 -->
      <div class="form-conatiner">
        <div>
          <div class="item-title">
            <div class="item-icon">
              <img src="@/assets/drive/icon1.png" alt="">
            </div>
            <div class="item-name">驱动信息</div>
          </div>
        </div>
        <el-form :model="form" label-position="left" :rules="rules" ref="formCustomRef">
          <div class="form-top">
            <div>
              <el-form-item label="驱动名称：" prop="name" class="label-feather">
                <el-input v-model="form.name" class="width476" placeholder="请输入驱动名称" :disabled="isDisable" />
              </el-form-item>
            </div>
            <div>
              <el-form-item label="驱动版本：" prop="version" class="label-feather">
                <el-input v-model="form.version" class="width476" placeholder="请输入驱动版本" :disabled="isDisable" />
              </el-form-item>
            </div>
          </div>
          <div class="form-top">
            <div>
              <el-form-item label="品牌：" prop="brand" class="label-feather">
                <el-input v-model="form.brand" class="width476" placeholder="请输入品牌" :disabled="isDisable" />
              </el-form-item>
            </div>
            <div>
              <el-form-item label="型号：" prop="model" class="label-feather">
                <el-input v-model="form.model" class="width476" placeholder="请输入型号" :disabled="isDisable" />
              </el-form-item>
            </div>
          </div>
          <div>
            <el-form-item label="上传驱动包：" prop="fileName">
              <div class="btn-groups">
                <div class="btn-upload" v-if="isDisable">
                  <img src="@/assets/menu/upload.png">点击上传
                </div>
                <el-upload action="" class="upload-demo" ref="upload" accept=".zip" :limit="1" :show-file-list="false"
                  :on-exceed="handleExceed" :http-request="changeUpload" v-else>
                  <template #trigger>
                    <div class="btn-upload" v-domPreventReClick>
                      <img src="@/assets/menu/upload.png">点击上传
                    </div>
                  </template>
                </el-upload>
                <!-- 上传中 -->
                <div v-if="isUploading" class="file-container">
                  <img src="@/assets/drive/loading.png">
                  上传{{ fileUploadPercent }}%...
                </div>
                <div v-if="!isUploading && form.fileName" class="file-container">
                  <img src="@/assets/drive/success.png">
                  上传成功
                </div>
                <div class="file-container" v-if="!isUploading && form.fileName">
                  <img src="@/assets/drive/file.png">
                  <div>{{ form.fileName }}</div>
                  <img src="@/assets/drive/close.png" class="is-close" @click="handleClear" v-domPreventReClick>
                </div>
              </div>
            </el-form-item>
          </div>
        </el-form>
      </div>

      <!-- 配置项 -->
      <div class="form-conatiner">
        <div>
          <div class="item-title">
            <div class="item-icon">
              <img src="@/assets/drive/icon2.png" alt="">
            </div>
            <div class="item-name">配置项</div>
          </div>
        </div>
        <el-form :model="form" ref="formCustom" label-position="left">
          <el-form-item label="部署模式" prop="name" class="label-feather">
            <el-radio-group v-model="form.deployMode" :disabled="isDisable">
              <el-radio label="STANDALONE">单机模式</el-radio>
              <el-radio label="CLUSTER">集群模式</el-radio>
              <el-radio label="BROADCAST">广播模式</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <el-form :model="form" label-position="left" ref="formCustom">
          <div class="form-top">
            <div>
              <el-form-item label="JVM参数" prop="jvmArgs" class="label-feather">
                <el-input v-model="form.jvmArgs" class="width476" placeholder="请输入JVM参数" :disabled="isDisable"
                  type="textarea" />
              </el-form-item>
            </div>
            <div>
              <el-form-item label="命令参数" prop="args" class="label-feather">
                <el-input v-model="form.args" class="width476" placeholder="请输入命令参数" :disabled="isDisable"
                  type="textarea" />
              </el-form-item>
            </div>
          </div>
          <div class="form-top">
            <div>
              <el-form-item label="环境变量" prop="envArgs" class="label-feather">
                <el-input v-model="form.envArgs" class="width476" placeholder="请输入环境变量" :disabled="isDisable"
                  type="textarea" />
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>

      <!-- 端口列表 -->
      <div class="form-conatiner">
        <div>
          <div class="item-title">
            <div class="item-icon">
              <img src="@/assets/drive/icon3.png" alt="">
            </div>
            <div class="item-name">端口列表</div>
            <!-- <div class="btn-groups" style="margin-left: 20px;">
              <el-button type="primary" class="add-btn" @click="() => isPointAdd = true"
                :disabled="isDisable">添加端口</el-button>
            </div> -->
          </div>
          <div class="tables">
            <div class="header-container">
              <div>端口</div>
              <div>协议</div>
              <div>说明</div>
              <div>操作</div>
            </div>
            <div class="main-box">
              <div class="add-container" v-for="(item, index) of pointList" :key="index">
                <div>
                  {{ item.port }}
                </div>
                <div>
                  {{ item.protocol }}
                </div>
                <div>
                  {{ item.desc }}
                </div>
                <div style="color:#fc2b2b;cursor: pointer" @click="handleDel(index)" v-domPreventReClick>
                  删除
                </div>
              </div>
              <div class="add-container" v-if="isAddPoint">
                <div>
                  <el-input-number v-model="point.port" placeholder="请输入端口" />
                </div>
                <div>
                  <el-select v-model="point.protocol" placeholder="请选择">
                    <el-option v-for="item in protocolList" :key="item.value" :label="item.name" :value="item.name" />
                  </el-select>
                </div>
                <div>
                  <el-input v-model="point.desc" placeholder="请输入说明" />
                </div>
                <div style="color:#367CC5;cursor: pointer" @click="handlePoint" v-domPreventReClick>
                  确认
                </div>
              </div>
            </div>
            <div class="add-column" @click="handleAddPoint" v-if="status === 'add' || status === 'edit'"
              v-domPreventReClick>
              <img src="@/assets/drive/add.png">
              添加端口
            </div>
          </div>
        </div>
      </div>

      <div class="footer-container">
        <div class="btn-conatiner">
          <div class="cancel-btn" @click="goback" v-domPreventReClick>返回</div>
          <div class="add-btn" @click="handleEdit" v-if="status === 'edit'" v-domPreventReClick>确认</div>
          <div class="add-btn" @click="handleAdd" v-if="status === 'add'" v-domPreventReClick>创建</div>
        </div>
      </div>
    </div>
    <pointAdd v-if="isPointAdd" @close="handlePointAdd" />
  </div>
</template>

<script setup>
import pointAdd from './pointAdd';
import { ref, reactive, onMounted, getCurrentInstance } from 'vue';
import { ElMessage } from "element-plus";
import api from "@/api/api"
import { useRouter, useRoute } from 'vue-router';
import { ElLoading } from 'element-plus'
const title = ref("创建驱动")
const router = useRouter();
const route = useRoute();
const isPointAdd = ref(false);
const isAddPoint = ref(false);
// const isEdit = ref(false);
const pointList = ref([]);
const protocolList = ref([
  {
    name: "TCP",
    value: "TCP",
  },
  {
    name: "UDP",
    value: "UDP",
  },
  {
    name: "HTTP",
    value: "HTTP",
  },
  {
    name: "MQTT",
    value: "MQTT",
  },
  {
    name: "WS",
    value: "WS",
  }
]);
const point = reactive({
  port: 0,
  protocol: "TCP",
  desc: "",
})
const form = reactive({
  name: "", // 驱动名称*
  version: "", // 驱动版本* 
  brand: "", // 品牌*
  model: "", // 型号*
  fileName: "", // 驱动包名称*
  filePath: "", // 驱动包路径*
  fileUrl: "", // 驱动包url
  jvmArgs: "",
  args: "",
  envArgs: "",
  remark: "",
  deployMode: "STANDALONE", // 部署模式
})
const isUploading = ref(false);
const handlePoint = () => {
  pointList.value.push(point);
  isAddPoint.value = false;
}
const handleAddPoint = () => {
  if (isAddPoint.value) return;
  point.port = 0;
  point.protocol = "TCP";
  point.desc = "";
  isAddPoint.value = true;
}
const rules = {
  name: [
    { required: true, message: "驱动名称不能为空", trigger: "change" },
    {
      min: 2,
      max: 24,
      message: '长度在 2 到 24 个字符',
      trigger: 'blur'
    }
  ],
  version: [
    { required: true, message: "驱动版本不能为空", trigger: "blur" },
  ],
  brand: [
    { required: true, message: "品牌不能为空", trigger: "blur" },
  ],
  model: [
    { required: true, message: "型号不能为空", trigger: "blur" },
  ],
  fileName: [
    { required: true, message: "驱动包不能为空", trigger: "blur" },
  ],
}
const isDisable = ref(false);
const id = ref(null);
const status = ref("add");
let currentInstance = "";
onMounted(() => {
  currentInstance = getCurrentInstance();
  const { type, driveId } = route.query;
  status.value = type;
  if (type === "view") {
    // 查看
    title.value = "查看驱动";
    isDisable.value = true;
    id.value = driveId;
    getDriveInfo()
  } else if (type === "edit") {
    title.value = "编辑驱动";
    id.value = driveId;
    getDriveInfo()
  }
})

const getDriveInfo = () => {
  if (!id.value) return;
  api.getDriversById(id.value).then(res => {
    if (res.code === "0") {
      const { name, version, brand, model, fileName, filePath, fileUrl, jvmArgs, args, envArgs, remark, deployMode, ports } = res.res.data;
      form.name = name;
      form.version = version;
      form.brand = brand;
      form.model = model;
      form.fileName = fileName;
      form.filePath = filePath;
      form.fileUrl = fileUrl;
      form.jvmArgs = jvmArgs;
      form.args = args;
      form.envArgs = envArgs;
      form.remark = remark;
      form.deployMode = deployMode;
      pointList.value = ports;
    }
  })
}
const changeUpload = (e) => {
  if (isDisable.value) return;
  const file = e.file
  let fd = new FormData();
  fd.append("file", file);
  isUploading.value = true;
  form.fileName = "";
  form.filePath = "";
  form.fileUrl = "";
  api.driverAddFile(fd, onDownloadProgress).then(res => {
    if (res.code === "0") {
      const { filename, path, url } = res.res.data;
      form.fileName = filename;
      form.filePath = path;
      form.fileUrl = url;
      fileUploadPercent.value = 100;
      setTimeout(() => {
        isUploading.value = false;
      }, 500);
      ElMessage.success("上传成功!")
    }
  })
}

const handleExceed = (e) => {
  currentInstance.proxy.$refs.upload.clearFiles();
  currentInstance.proxy.$refs.upload.handleStart(e[0]);
  currentInstance.proxy.$refs.upload.submit();
  form.fileName = "";
  form.filePath = "";
  form.fileUrl = "";
}
const fileUploadPercent = ref(0);
const onDownloadProgress = (progressEvent) => {
  let complete = ((progressEvent.loaded / progressEvent.total) * 100).toFixed(
    0
  );
  fileUploadPercent.value = parseInt(complete) < 99 ? parseInt(complete) : 99;
};

const handleClear = () => {
  if (isDisable.value) return;
  form.fileName = "";
  form.filePath = "";
  form.fileUrl = "";
}
const handleDel = (index) => {
  if (isDisable.value) return;
  pointList.value.splice(index, 1)
}
const handlePointAdd = (val) => {
  if (val) {
    pointList.value.push(val);
  }
  isPointAdd.value = false;
}

const formCustomRef = ref(null); // 产品信息校验
const handleAdd = () => {
  formCustomRef.value.validate((valid) => {
    if (valid) {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      form.ports = pointList.value;
      api.createDriver(form).then(res => {
        if (res.code === "0") {
          ElMessage.success("创建成功！")
          router.push({
            path: "/drive/list",
          })
        }
        loading.close();
      })
    } else {
      return false;
    }
  });
}

const handleEdit = () => {
  formCustomRef.value.validate((valid) => {
    if (valid) {
      if (!id.value) return
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      form.ports = pointList.value;
      api.editDrivers(id.value, form).then(res => {
        if (res.code === "0") {
          loading.close();
          ElMessage.success("编辑成功！")
          router.push({
            path: "/drive/list",
          })
        }
      })
    } else {
      return false;
    }
  });
}
const goback = () => {
  router.push({
    path: "/drive/list",
  })
}
</script>

<style lang="less" scoped>
.footer-container {
  border-top: 1px solid #E9ECF0;
}

.btn-upload {
  width: 136px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #B7BBC0;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 15px;
    height: 15px;
    margin-right: 4px;
  }
}

.width476 {
  width: 476px;
}

.breadcrumb-conatiner {
  box-sizing: border-box;
  padding: 20px 28px;
  border-bottom: 1px solid #E9ECF0;
}

.file-container {
  display: flex;
  align-items: center;
  margin-top: 10px;

  img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  .is-close {
    margin-left: 20px;
    width: 14px;
    height: 14px;
  }
}

.drive-add {
  box-sizing: border-box;
}

.drive-container {
  box-sizing: border-box;
  padding: 0 28px;
  background: #fff;
}

.form-conatiner {
  box-sizing: border-box;
  // padding: 0 12px 40px 0px;

  .line {
    width: 100%;
    height: 1px;
    background: #eee;
  }

  &>div {
    .item-title {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 20px 0;

      .item-icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .item-name {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  .radio-group-container {
    display: flex;
    align-items: center;
  }

  .form-top {
    display: flex;
    align-items: center;

    &>div:first-child {
      margin-right: 20px;
    }
  }
}

.tables {
  width: 1187px;

  .header-container {
    display: flex;
    align-items: center;
    height: 45px;
    text-align: center;
    background: #F6F7F9;
    color: #333333;

    &>div {
      width: 25%;
    }
  }

  .main-box {
    &>div:nth-child(odd) {
      background: #FFFFFF;
    }

    &>div:nth-child(even) {
      background: #FBFBFB;
    }
  }

  .add-container {
    display: flex;
    align-items: center;
    height: 58px;
    text-align: center;
    // background: #F6F7F9;
    color: #333333;

    &>div {
      width: 25%;
    }
  }

  .add-column {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 58px;
    color: #367CC5;
    cursor: pointer;

    img {
      width: 22px;
      height: 22px;
      margin-right: 10px;
    }
  }
}

:deep(.el-table th.el-table__cell) {
  background-color: #F6F7F9;
  height: 50px;
  font-weight: normal;
  color: #000000;
}

:deep(.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell) {
  background-color: #FBFBFB;
}

:deep(.el-table td.el-table__cell div) {
  font-size: 16px;
  line-height: 50px;
}

.btn-conatiner {
  float: right;
  margin-top: 14px;
  margin-right: 50px;
  display: flex;
  align-items: center;

  .add-btn {
    margin-left: 20px;
    border-radius: 4px;
    width: 136px;
    cursor: pointer;
    text-align: center;
    background: #367CC5;
    color: #ffffff;
    height: 34px;
    line-height: 34px;
    font-size: 16px;
  }

  .cancel-btn {
    box-sizing: border-box;
    border-radius: 4px;
    width: 136px;
    cursor: pointer;
    text-align: center;
    color: #333333;
    border: 1px solid #B7BBC0;
    height: 34px;
    line-height: 34px;
    font-size: 16px;
  }
}
</style>

<style lang="less">
.label-feather {
  .el-form-item__label {
    width: 109px;
  }
}
</style>